import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barner',
  templateUrl: './barner.component.html',
  styleUrls: ['./barner.component.css']
})
export class BarnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
